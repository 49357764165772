
import { defineComponent } from 'vue';
import Icon from '@/components/Icon.vue';
import Tooltip from '@/components/defaults/tooltip/Tooltip.vue';
import { mapActions } from 'vuex';

export default defineComponent({
  name: 'Timeline',
  components: {
    Icon,
    Tooltip,
  },
  methods: {
    ...mapActions('historic', {
      setUndo: 'setUndo',
      setRedo: 'setRedo',
      setBackup: 'generateBackup',
    }),
    undo(): void {
      this.setUndo();
    },
    redo(): void {
      this.setRedo();
    },
  },
});
